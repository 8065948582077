import React, { useState } from "react"
import { Image } from "./common/image.component"
import axios from "axios"
import { Link } from "gatsby"

export const ForSaleDetails = () => {
  const [car, setCar] = useState(null)
  const [currentImage, setCurrentImage] = useState(null)

  if (!car) {
    const queryParams = new URLSearchParams(typeof window !== "undefined" && window.location.search)
    const id = queryParams.get("id")
    axios.get(
      `https://api.usatrt.com/api/sale/${id}`,
      {
        headers: {
          Accept: "application/json",
        },
      },
    ).then(result => {
      setCar(result.data)
      setCurrentImage(result.data.Pictures && result.data.Pictures[0] || null)
    })
 }
  return (
    <div>
      {car ? (
        <div className="section">
          <div className="container">

            <div className="row">
              <div className="col-sm-10 col-sm-offset-1 col-md-10 col-md-offset-1">
                <Link to={"/for-sale"} className="mb15 d-inline-block">&lt; Back</Link>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="mb25">
                      <Image image={currentImage} className="img-lg" alt="card image"/>
                    </div>
                    <div className="row mb25">
                      {car.Pictures && car.Pictures.sort((a, b) => {
                        const aParam = new URLSearchParams(a)
                        const bParam = new URLSearchParams(b)
                        if (aParam.get("filename") > bParam.get("filename")) {
                          return 1
                        } else if (aParam.get("filename") < bParam.get("filename")) {
                          return -1
                        }
                        return [0]
                      }).map(image => {
                        return (
                          <div className="col-xs-6 col-sm-4 mb25" onClick={() => setCurrentImage(image)}>
                            <Image image={image} className="img-sm" alt="card image"/>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="for-sell-details-wrapper">
                      <div className="d-flex flex-wrap">
                        <div className="mr-auto">
                          {car.ProductId ? (
                            <h5 className="details-title">{car.ProductId}</h5>
                          ) : (
                            <h5 className="details-title">{car.Year} {car.Make} {car.Model}</h5>
                          )}
                        </div>
                        <div className="mb25">
                          <a href="#quote" data-toggle="modal" className="btn btn-primary">Contact Sales</a>
                        </div>
                      </div>
                      <div className="description-block">
                        <p>
                          {car.Description}
                        </p>
                        <ul>
                          <li className="row">
                            <div className="col-xs-6">Vin Number</div>
                            <div className="col-xs-6">{car.Vin || ""}</div>
                          </li>
                          <li className="row">
                            <div className="col-xs-6">Year</div>
                            <div className="col-xs-6">{car.Year || ""}</div>
                          </li>
                          <li className="row">
                            <div className="col-xs-6">Make</div>
                            <div className="col-xs-6">{car.Make || ""}</div>
                          </li>
                          <li className="row">
                            <div className="col-xs-6">Model</div>
                            <div className="col-xs-6">{car.Model || ""}</div>
                          </li>
                          <li className="row">
                            <div className="col-xs-6">Color</div>
                            <div className="col-xs-6">{car.Color || ""}</div>
                          </li>
                          <li className="row">
                            <div className="col-xs-6">Body Type</div>
                            <div className="col-xs-6">{car.BodyType || ""}</div>
                          </li>
                          <li className="row">
                            <div className="col-xs-6">Engine Cylinders</div>
                            <div className="col-xs-6">{car.EngineCyl || ""}</div>
                          </li>
                          <li className="row">
                            <div className="col-xs-6">Engine Liters</div>
                            <div className="col-xs-6">{car.EngineLiters || ""}</div>
                          </li>
                          <li className="row">
                            <div className="col-xs-6">Fuel Type</div>
                            <div className="col-xs-6">{car.EngineType || ""}</div>
                          </li>
                          <li className="row">
                            <div className="col-xs-6">Location</div>
                            <div
                              className="col-xs-6">{car.LoadingWarehouse || car.PortOfExport || ""}</div>
                          </li>

                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : <span><div className="loader"/></span>}
    </div>
  )
}



