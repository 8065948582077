import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"
import { Html } from "./common/html.component"
import { RichText } from "./common/rich-text.component"

const query = graphql`
    query formPopup {
        prismic {
            allForms {
                edges {
                    node {
                        _meta {
                            lang
                            uid
                            type
                        }
                        form_name
                        form_script
                        form_url
                    }
                }
            }
        }
    }
`

export function FormPopup({lang, id,  uid})  {
    return (
      <StaticQuery
        query={query}
        render={withPreview(data => {
          const form = data.prismic.allForms.edges.find(
            item => item.node._meta.lang === lang && item.node._meta.uid === 'request-a-quote1')
          if (form) {
            return (
              <div className="modal fade" id={id || uid}>
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header no-border">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                      >
                        <span
                          className="glyphicon glyphicon-remove"
                          aria-hidden="true"
                        />
                      </button>
                      <div className="modal-header-title row">
                        <RichText
                          className="col-sm-10 col-sm-offset-1 col-md-offset-0 p0 col-md-12"
                          render={form.node.form_name}
                        />
                      </div>
                    </div>
                    <div className="modal-body pb50">
                      <div className="row">
                        <div className="col-sm-10 col-sm-offset-1">
                          <Html html={form.node.form_script} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          return null
        }, query)}
      />
    );
}
