import React from "react"
import Layout from "../components/layout.component"
import { Navbar } from "../components/navbar.component"
import { ForSaleDetails } from "../components/for-sale-details.ccomponent"
import { FormPopup } from "../components/form-popup.component"

const SellPage = () => {
  return (
    <Layout>
      <Navbar slides={[{}]}/>
      <div className="header header-light" style={{ backgroundColor: "white" }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1 col-md-10 col-md-offset-1">
              <div className="section">
                <h1>Cars for Sale</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <main className="main_alt">
        <ForSaleDetails/>
      </main>
      <FormPopup id={'quote'} uid={'request-a-quote1'} lang={"en-us"}/>
    </Layout>
  )
}

export default SellPage


